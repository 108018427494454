@import "react-toastify/dist/ReactToastify.min.css";
@import url('./assets/styles/index.scss');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@layer components {
  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply bg-secondary-900 border-0 text-base cursor-pointer;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply dark:border-neutral-400 shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-secondary-900 border-0 top-8 z-max;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-white bg-secondary-900 hover:bg-black text-sm transition-all;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-secondary-900;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-400 dark:text-neutral-200;
  }
}
