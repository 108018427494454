* {
  box-sizing: border-box;
}

*:focus {
    outline: none;
}

::-webkit-scrollbar { 
display: none; 
}

:root {
  font-size: 16px;
  @apply font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    font-size: 15px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

button {
  outline: none;
}

.css-ljf9am-MuiButtonBase-root-MuiIconButton-root, .css-idly8-MuiButtonBase-root-MuiIconButton-root{
  position: inherit !important;
}
