@mixin gradient-background-blend-mode {
  background-blend-mode: multiply, color, normal, normal;
}

.gradient-secondary-1 {
  @apply rounded;
  @include gradient-background-blend-mode;
  background: linear-gradient(270deg, #6CC24A 0%, #9DFF74 100%);
}

.gradient-secondary-2 {
  @apply rounded;
  @include gradient-background-blend-mode;
  background: linear-gradient(90deg, #6CC24A 0%, #9DFF74 39.6%, #9DFF74 58.8%, #6CC24A 100%);
}

.gradient-secondary-3 {
  @apply rounded;
  @include gradient-background-blend-mode;
  background: linear-gradient(295.28deg, #6CC24A -3.28%, #8DE369 9.8%, #9AF076 20.51%, #9FF57A 30.94%, #85DB62 80.3%, #6CC24A 102.53%);
}

.gradient-grey-1 {
  @apply rounded;
  @include gradient-background-blend-mode;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #28313C;
}

.gradient-grey-2 {
  @apply rounded;
  @include gradient-background-blend-mode;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(180deg, #627382 0%, rgba(76, 85, 95, 0.25) 5.94%), linear-gradient(180deg, #4D5E71 0%, #415366 99.78%);
}

.gradient-grey-3 {
  @apply rounded;
  @include gradient-background-blend-mode;
  background: linear-gradient(180deg, rgba(136, 162, 181, 0.3) 0%, rgba(103, 123, 136, 0.3) 100%);
}
