html,
body,
#app,
#app > div,
#root {
  height: 100%;
  background-color: black;
  overflow: hidden;
}
.css-5isjjr-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  background-color: #434343 !important;
}
.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected {
  background-color: #6e6e6e !important;
}

.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem {
  &:hover {
    background: #4e4e4e !important;
  }
}
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  &:hover {
    background: #434343;
  }
}

.fill-transparent {
  fill: transparent;
}

.navlink {
  margin-bottom: 16px !important;
}
.cloud-camera-spinner {
  @apply relative;
}
.cloud-camera-spinner .cloud {
  @apply w-14;
}
.cloud-camera-spinner .camera {
  @apply absolute top-5 left-4;
}
.cloud-camera-spinner.loading .cloud {
  animation: linear swipe-animation 8s;
  animation-iteration-count: infinite;
}
.cloud-camera-spinner.loading .camera {
  @apply absolute top-5 left-4;
  animation: linear swipe-animation 6s;
  animation-iteration-count: infinite;
}

.toast-message {
  color: rgb(204, 0, 0);
  background: rgba(255, 255, 255, 0.245);
}

.scrollable {
  -webkit-mask-image: linear-gradient(90deg, #000 85%, transparent);
}

@keyframes swipe-animation {
  from {
    transform: rotateY(0deg);
  }
  from {
    transform: rotateY(360deg);
  }
}

.secondary-sidebar-vertical-ruler {
  height: calc(100% - 29px);
}

.react-tiny-popover-container {
  z-index: 120;
}

.checkbox input[type='checkbox'] {
  display: none;
  cursor: pointer;
}

.checkbox input[type='checkbox'] + label {
  width: 16px;
  height: 16px;
  border: 2px solid #d1d1d1 !important;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  margin-right: 8px;
}
/* Style the checked state */
.checkbox input[type='checkbox']:checked + label {
  width: 16px;
  height: 16px;
  background-color: #7fd456;
  border: 0px !important; 
  border-radius: 2px;
  margin-right: 8px;
  background-image: url('./assets/img/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
}

.custom-dropdown {
  margin-left: 100px;
  margin-top: -100px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiListItem-root.Mui-selected {
    background-color: 'yellow',
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
