.form-wrapper {
  @apply mb-4;
}

.form-control {
  @apply border border-solid outline-none;
}

.form-control.theme-primary {
  @apply bg-primary-900 border-primary-900 text-white;

  &.text-input {
    @apply bg-black border-black text-white;
  }
}

.form-control.size-sm {
  @apply text-sm px-4 py-0.5;
}

.form-control.is-invalid {
  @apply border-error-100;
}

.form-control ~ .helper-text {
  @apply text-xs;
}

.form-control.is-invalid ~ .helper-text {
  @apply text-error-100;
}
