.notistack-Snackbar {
  @apply max-w-115;

  .notistack-MuiContent {
    @apply flex-nowrap p-3 shadow-elevation5;

    &.notistack-MuiContent-success {
      @apply bg-primary-400 text-black;
    }

    &.notistack-MuiContent-info {
      @apply bg-info-200 text-black;
    }

    &.notistack-MuiContent-warning {
      @apply bg-warning-300 text-black;
    }

    &.notistack-MuiContent-error {
      @apply bg-error-200 text-white;
    }

    #notistack-snackbar {
      @apply flex-1 typo-body2;

      .MuiSvgIcon-root {
        @apply mr-3;
      }

      & + div {
        @apply mr-0;
      }
    }

    &:not(.notistack-MuiContent-error) {
      .MuiIconButton-root {
        color: black !important;

        &:hover .MuiTouchRipple-root {
          @apply bg-black bg-opacity-10;
        }
      }
    }
  }
}
